import React, { ChangeEvent, Fragment } from 'react';
import { ITaskType, TaskForm as TaskFormClass, ITaskDetail } from '../../../../app/models/Task';
import { IRun, IRunBasicInfo, RunCustomerOrderForm } from '../../../../app/models/Run/Run';
import { Modal, Button, FormControl, Well, Badge, Row, Col } from 'react-bootstrap';
import { Loader, ErrorBox, FieldGroup, Form, Callout, ContentBox } from '../../../common/uiElements';
import { ILeaveRequestSimple } from '../../../../app/models/LeaveRequest';
import moment from 'moment';
import Icon from 'react-fontawesome';
import TaskApi from '../../../../app/api/TaskApi'
import componentRequestHandler from '../../../../app/api/helpers/componentRequestHandler';
import { IWorkerForTaskOrRoster } from '../../../../app/models/Worker';
import { IError, IReactSelectReturn, IResponsePOST } from '../../../../app/models/Application';
import { IVehicleBasicInfo, VehicleTypes } from '../../../../app/models/Vehicle';
import { RunsListSearch } from '../../../common/inputs';
import TaskForm from './TaskForm';
import RunForm from '../Run/RunForm';
import { TaskPOST, TaskPUT, TaskUpdateReasonForm, TaskCANCEL, TaskDELETE } from '../../../../app/models/Task';
import { getTaskUpdateReasonCategories, checkTaskOverlappingOtherTasks, checkVehicleAlreadyInUse, getTaskStatus, getStoreRequiresTailgateIssue } from './tasksHelper';
import { TaskUpdateReasonsList } from '../../../common/lists';
import confirm from '../../../../app/helpers/confirm';
import TaskListDetailsSimple from './list/TaskListSimple';
import { ItemInterface } from 'react-sortablejs';
import StaffRosterModal from '../StaffRoster/StaffRosterModal';
import $ from 'jquery';
import FontAwesome from 'react-fontawesome';
import alert from '../../../../app/helpers/alert';
// import RunOrdersTimeline from '../Run/RunOrdersTimeline';

interface IProps {
	tasksList: ITaskDetail[];
	taskId?: number;
	initialDate?: string;
	initialStartingDepotId?: string;
	initialStartingDepotName?: string;
	show?: boolean;
	isReadOnly?: boolean;
	updateReasonNotRequired?: boolean;
	isChangeDateEnabled?: boolean;
	isChangePlaceEnabled?: boolean;
	confirmModalClose?: boolean;
	onClose: () => void;
	onSaveComplete: (newTaskDetails: TaskFormClass, taskId?: string) => void;
}

interface IState {
	taskDetails: TaskFormClass;
	originalTaskDetails?: TaskFormClass;
	selectedRun?: IRun;
	employeeOverlappingLeaveRequest?: ILeaveRequestSimple;
	updateReasonsFormData?: TaskUpdateReasonForm[];
	showUpdateReasonForm: boolean;
	showAddStaffRosterModal: boolean;
	deleteTaskPermanently: boolean;
	anyChangeHasBeenMade: boolean;
	isSaving: boolean;
	isLoadingTask: boolean;
	isLoadingRun: boolean;
	isLoadingLeaveRequests: boolean;
	errorSaving?: IError;
	errorLoadingTask?: IError;
	errorLoadingRun?: IError;
	errorLoadingLeaveRequests?: IError;
}

class RosterTaskModal extends React.Component<IProps, IState> {
	unmounted = false;
	
	constructor(props: IProps) {
		super(props);

		this.state = { 
			taskDetails: {
				...new TaskFormClass(),
				date: this.props.initialDate || '',
				startingDepotId: props.initialStartingDepotId || '',
				startingDepotName: props.initialStartingDepotName
			},
			originalTaskDetails: undefined,
			selectedRun: undefined,
			showUpdateReasonForm: false,
			showAddStaffRosterModal: false,
			deleteTaskPermanently: false,
			anyChangeHasBeenMade: false,
			employeeOverlappingLeaveRequest: undefined,
			isSaving: false,
			isLoadingTask: false,
			isLoadingRun: false,
			isLoadingLeaveRequests: false,
			errorSaving: undefined,
			errorLoadingTask: undefined,
			errorLoadingRun: undefined,
			errorLoadingLeaveRequests: undefined
		}
	}

	componentDidUpdate(prevProps: IProps) {
		if (this.props.taskId !== prevProps.taskId)
			this.fetchTaskDetails();
	}	

	componentDidMount() {
		this.fetchTaskDetails();
	}

	componentWillUnmount(): void {
		this.unmounted = true;
	}

	fetchTaskDetails = () => {
		const { taskId } = this.props;
		if (!taskId) return;

		const promise = () => TaskApi.getTaskDetails(taskId);
		componentRequestHandler(this, promise, 'originalTaskDetails', { 
			loadingAttrName: 'isLoadingTask',
			errorAttrName: 'errorLoadingTask'
		})
		.then((data: ITaskDetail) => {
			this.setState({
				taskDetails: new TaskFormClass(data),
				originalTaskDetails: new TaskFormClass(data)
			})
			return data;
		})
		.then(task => {
			if (this.shouldRenderRunSearch() && task.runId)
				this.fetchRunDetails(task.runId);
		})
	}

	saveNewTask = () => {
		const task = new TaskPOST(this.state.taskDetails);
		const promise = () => TaskApi.createTask(task);

		componentRequestHandler(this, promise, undefined, {
			loadingAttrName: 'isSaving',
			errorAttrName: 'errorSaving',
			showSuccessNotification: true
		})
		.then(({ dbKeyReference }: IResponsePOST) => this.props.onSaveComplete && this.props.onSaveComplete(this.state.taskDetails, dbKeyReference))
		.catch(() => this.setState({ showUpdateReasonForm: false }));
	}

	saveTaskChanges = () => {
		const { taskDetails, updateReasonsFormData, deleteTaskPermanently } = this.state;
		let promise;

		if (taskDetails.isCancelled) {
			const { updateReasonId, updateDescription  } = updateReasonsFormData![0];
			// If Task was deleted
			if (deleteTaskPermanently)
				promise = () => TaskApi.deleteTask(taskDetails.id!, new TaskDELETE(updateReasonId, updateDescription));
			else // If Task was cancelled
				promise = () => TaskApi.cancelTask(taskDetails.id!, new TaskCANCEL(updateReasonId, updateDescription));
		} 
		else // If it's just an update
			promise = () => TaskApi.updateTask(taskDetails.id!, new TaskPUT(taskDetails, updateReasonsFormData!));


		componentRequestHandler(this, promise, undefined, {
			loadingAttrName: 'isSaving',
			errorAttrName: 'errorSaving',
			showSuccessNotification: true
		})
		.then(() => this.props.onSaveComplete && this.props.onSaveComplete(taskDetails))
		.catch(() => this.setState({ showUpdateReasonForm: false }));
	}

	fetchRunDetails = (pRunId?: number) => {
		if (this.props.taskId && !pRunId)
			return;

		const { runId } = this.state.taskDetails;
		if (!runId) 
			return this.setState({ selectedRun: undefined });

		const promise = () => TaskApi.getTaskDetailsWithRunData(parseInt(runId));
		componentRequestHandler(this, promise, 'selectedRun', {
			loadingAttrName: 'isLoadingRun',
			errorAttrName: 'errorLoadingRun'
		})
		.then(task => {
			const { runId, startTimeLocal, budgetedTime } = task as ITaskDetail;
			this.setState({
				taskDetails: {
					...this.state.taskDetails,
					runId: runId?.toString() || '',
					budgetedTime: budgetedTime?.toString() || '',
					startTime: moment(startTimeLocal).format('HH:mm')
				}
			})
		})
	}

	checkUpdateReasonsForm = () => {
		const { originalTaskDetails, taskDetails } = this.state;
		if (!originalTaskDetails || !taskDetails) 
			return;

		const updateReasonsFormData = getTaskUpdateReasonCategories(originalTaskDetails, taskDetails);
		
		if (updateReasonsFormData.length === 0)
			return this.saveTaskChanges();

		this.setState({ 
			updateReasonsFormData,
			showUpdateReasonForm: true
		});
	}	

	reorganizeDeliverySequence = () => {
		const { RunCustomerOrders } = this.state.taskDetails;
		RunCustomerOrders?.forEach((order, index) => order.deliverySequence = index + 1);
		this.changeForm({ RunCustomerOrders }, true)
	}

	showUpdateReasonForm = () => {
		this.setState({ 
			showUpdateReasonForm: true,
			errorSaving: undefined,
		}, this.checkUpdateReasonsForm);
	}

	handleClickGoBack = () => {
		this.changeForm({ isCancelled: false })
			.then(() => {
				this.setState({ 
					showUpdateReasonForm: false,
					updateReasonsFormData: undefined,
					errorSaving: undefined
				});
			})
	}

	resetForm = () => {
		return new Promise((resolve => {
			const { initialDate, initialStartingDepotId, initialStartingDepotName } = this.props;

			this.setState({
				selectedRun: undefined,
				anyChangeHasBeenMade: false,
				taskDetails: {
					...new TaskFormClass(), 
					date: initialDate || '',
					startingDepotId: initialStartingDepotId || '',
					startingDepotName: initialStartingDepotName
				}
			}, resolve)
		}))
	}

	basicDataEntered = () => {
		const { taskTypeId, startingDepotId, date } = this.state.taskDetails;
		return taskTypeId && startingDepotId && date && moment(date) > moment('2000-01-01');
	}

	changeForm = (newData: object, doNotCountAsChange?: boolean) => {
		return new Promise(resolve => {
			this.setState({
				anyChangeHasBeenMade: doNotCountAsChange ? false : true,
				taskDetails: {
					...this.state.taskDetails,
					...newData
				}
			}, resolve);
		})
	}

	shouldRenderRunSearch = () => {
		const {startingDepotId, date, TaskType } = this.state.taskDetails;
		if (!startingDepotId || !date || !TaskType) 
			return false;
		
		const { isRunRequired, isDriversLicenceRequired } = TaskType;
		return isRunRequired && !isDriversLicenceRequired;
	}

	showRunDetailsBlock = () => this.shouldRenderRunSearch() || this.isAddingRun() || this.state.selectedRun;

	toggleShowAddStaffRosterModal = () => {
		this.setState({ showAddStaffRosterModal: !this.state.showAddStaffRosterModal })
	}

	onCloseAddStaffRosterModal = () => {
		this.setState({ 
			showAddStaffRosterModal: false 
		}, this.handleChangeWorker)
	}

	isAddingRun = () => {
		const {startingDepotId, date, TaskType } = this.state.taskDetails;
		if (!startingDepotId || !date || !TaskType) 
			return false;
		
		const { isRunRequired, isDriversLicenceRequired } = TaskType;
		return isRunRequired && isDriversLicenceRequired;
	}
	
	handleChangeInput = (id: string, value?: string | boolean) => {
		this.changeForm({ [id]: value })
	}

	handleCheckMainVehicleNotTracked = (isMainVehicleNotTracked: boolean) => {
		this.changeForm({ 
			isMainVehicleNotTracked,
			mainVehFleetNumber: '',
			MainVehicle: undefined
		})
	}

	handleCheckTrailer1NotTracked = (isTrailer1NotTracked: boolean) => {
		this.changeForm({ 
			isTrailer1NotTracked,
			trailer1FleetNumber: '',
			Trailer1: undefined
		})
	}

	handleCheckTrailer2NotTracked = (isTrailer2NotTracked: boolean) => {
		this.changeForm({ 
			isTrailer2NotTracked,
			trailer2FleetNumber: '',
			Trailer2: undefined
		})
	}
	
	handleChangeSubcontractor	= (subContractorAbn?: string) => {
		this.changeForm({ 
			subContractorAbn,
			Worker: undefined,
			workerId: undefined,
			isDriverNotTracked: false,
			isMainVehicleNotTracked: false,
			isTrailer1NotTracked: false,
			isTrailer2NotTracked: false
		})
	}

	handleChangeWorker = (Worker?: IWorkerForTaskOrRoster) => {
		this.changeForm({
			workerId: Worker?.azureId,
			Worker: Worker?.azureId ? Worker : undefined
		})

		// Check if worker has a roster for the date (only if its not a sub contractor)
		const { date, subContractorAbn } = this.state.taskDetails;
		if (!subContractorAbn && Worker?.azureId && !Worker.Roster)
			return confirm(
				(
					<h4 className="text-center">
						<b>{Worker.firstName}</b> is not rostered for <b>{moment(date).format('dddd')} ({moment(date).format('DD/MM')})</b> and can{"'"}t be allocated to this task.
						<br/><br/>
						Do you wish to roster <b>{Worker.firstName}</b> for that date?
					</h4>
				),
				this.toggleShowAddStaffRosterModal,
				this.handleChangeWorker
			);
	}

	handleChangeTaskType = (e: any, TaskType?: ITaskType) => {
		const taskTypeId = e.target.value;
		this.resetForm()
			.then(() => this.changeForm({ taskTypeId, TaskType }));
	}

	handleChangeStartTime = (startTime: string) => {
		this.changeForm({ startTime, isStartTimeAdjustNeeded: false });
	}

	handleChangeRun = (selectedRun?: IRunBasicInfo) => {
		const runId = selectedRun?.id;
		this.changeForm({ runId })
			.then(() => {
				if (!runId)
					return this.setState({ selectedRun: undefined })

				this.fetchRunDetails(runId)
			});
	}

	handleChangeVehicle = (fieldId: string, vehicle?: IVehicleBasicInfo) => {
		const value = vehicle?.fleetNumber;
		const newData = { [fieldId]: value } as any;
		if (fieldId === 'mainVehFleetNumber') {
			newData.MainVehicle = value ? vehicle : undefined;
			if (vehicle && vehicle.typeId !== VehicleTypes.PRIME_MOVER) {
				newData.Trailer1 = undefined;
				newData.Trailer2 = undefined;
				newData.trailer1FleetNumber = '';
				newData.trailer2FleetNumber = '';
				newData.isTrailer1NotTracked = false;
				newData.isTrailer2NotTracked = false;
			}
		}
		else if (fieldId === 'trailer1FleetNumber') {
			newData.Trailer1 = value ? vehicle : undefined;
			if (vehicle && vehicle.typeId !== VehicleTypes.PRIME_MOVER) {
				newData.Trailer2 = undefined;
				newData.trailer2FleetNumber = '';
			}
		} 
		else if (fieldId === 'trailer2FleetNumber') {
			newData.Trailer2 = value ? vehicle : undefined;
		}

		this.changeForm(newData);
	}

	handleClickCancelTask = () => {
		this.changeForm({ isCancelled: true })
			.then(this.showUpdateReasonForm)
	}
	
	handleClickSaveTask = () => {
		// Check if it overlaps other tasks of the employee (if there's one)
		const { tasksList } = this.props;
		const { id, Worker, date, startTime, budgetedTime, mainVehFleetNumber, trailer1FleetNumber, trailer2FleetNumber } = this.state.taskDetails;
		const { MainVehicle, Trailer1, Trailer2 } = this.state.taskDetails;

		const ordersWithTailgateRequiredIssue = this.state.taskDetails.RunCustomerOrders?.filter(order => getStoreRequiresTailgateIssue(order.storeRequiresTailgate, MainVehicle, Trailer1, Trailer2)) || [];
		if (ordersWithTailgateRequiredIssue.length > 0) {
			return alert(
				<>
					<Callout icon="warning" title="Tailgate Vehicle Required">
						The following stores require a tailgate vehicle. You must allocate a vehicle that has a tailgate in order to continue.
						<br />
						<ul>
							{
								ordersWithTailgateRequiredIssue.map(order => (
									<li key={order.id}>
										<b>{(order.storeNumber ? `${order.storeNumber} - ` : '') + order.placeName}</b>
									</li>
								))
							}
						</ul>
					</Callout>
				</>
			);
		}

		if (!this.state.showUpdateReasonForm) {
			const newTaskDateTime = moment(date + ' ' + startTime).format('YYYY-MM-DDTHH:mm')
			const overlappingTask = Worker && checkTaskOverlappingOtherTasks(tasksList, Worker.azureId, newTaskDateTime, parseFloat(budgetedTime!), id)
			
			let taskMainVehicleAlreadyInUse: ITaskDetail | undefined;
			let taskTrailer1AlreadyInUse: ITaskDetail | undefined;
			let taskTrailer2AlreadyInUse: ITaskDetail | undefined;
			let vehiclesAlreadyInUse: string[] = [];
			if (mainVehFleetNumber) {
				taskMainVehicleAlreadyInUse = checkVehicleAlreadyInUse(tasksList, mainVehFleetNumber, newTaskDateTime, parseFloat(budgetedTime!), this.state.taskDetails);
				if (taskMainVehicleAlreadyInUse) vehiclesAlreadyInUse.push(mainVehFleetNumber);
			}

			if (trailer1FleetNumber) {
				taskTrailer1AlreadyInUse = checkVehicleAlreadyInUse(tasksList, trailer1FleetNumber, newTaskDateTime, parseFloat(budgetedTime!), this.state.taskDetails);
				if (taskTrailer1AlreadyInUse) vehiclesAlreadyInUse.push(trailer1FleetNumber);
			}

			if (trailer2FleetNumber) {
				taskTrailer2AlreadyInUse = checkVehicleAlreadyInUse(tasksList, trailer2FleetNumber, newTaskDateTime, parseFloat(budgetedTime!), this.state.taskDetails);
				if (taskTrailer2AlreadyInUse) vehiclesAlreadyInUse.push(trailer2FleetNumber);
			}

			if (overlappingTask || vehiclesAlreadyInUse.length > 0) {
				return alert((
					<Fragment>
						{
							Worker && overlappingTask && (
								<Fragment>
									<Callout icon="warning" title="Overlapping Tasks">
										This task overlaps another task that {Worker.firstName} is assigned to. You must adjust the start time or budgeted time of the tasks so they don't overlap. 
									</Callout>
									<ContentBox bordered title={<Fragment><b>{Worker.firstName}</b> is assigned to this task:</Fragment>}>
										<TaskListDetailsSimple task={overlappingTask} />
									</ContentBox>
								</Fragment>
							)
						}
						{
							vehiclesAlreadyInUse.length > 0 && (
								<Fragment>
									<Callout icon="warning" title="Vehicle Already in Use">
										The { vehiclesAlreadyInUse.length === 1 ? 'vehicle' : 'vehicles' }&nbsp;
										<b>{ vehiclesAlreadyInUse.join(', ') }</b>&nbsp;
										{ vehiclesAlreadyInUse.length === 1 ? 'is' : 'are' }
										&nbsp;already allocated to another task that overlaps this one. You must adjust the start time or budgeted time of the tasks so they don't overlap in order to continue.
									</Callout>
									{
										taskMainVehicleAlreadyInUse && (
											<ContentBox bordered title={<Fragment><b>{ taskMainVehicleAlreadyInUse.MainVehicle?.fleetNumber }</b> is allocated to this task:</Fragment>}>
												<TaskListDetailsSimple task={taskMainVehicleAlreadyInUse} />
											</ContentBox>
										)
									}
									{
										taskTrailer1AlreadyInUse && (
											<ContentBox bordered title={<Fragment><b>{ taskTrailer1AlreadyInUse.Trailer1?.fleetNumber }</b> is allocated to this task:</Fragment>}>
												<TaskListDetailsSimple task={taskTrailer1AlreadyInUse} />
											</ContentBox>
										)
									}
									{
										taskTrailer2AlreadyInUse && (
											<ContentBox bordered title={<Fragment><b>{ taskTrailer2AlreadyInUse.Trailer2?.fleetNumber }</b> is allocated to this task:</Fragment>}>
												<TaskListDetailsSimple task={taskTrailer2AlreadyInUse}  />
											</ContentBox>
										)
									}
								</Fragment>
							)
						}
					</Fragment>
				));
			}
		}

		this.onSaveTask();
	}

	onCompleteSaveStaffRoster = () => {
		this.toggleShowAddStaffRosterModal();

		const $workerDropDownValueLabel = $('#workerId_container').find('.Select-value-label');
		if ($workerDropDownValueLabel)
			$workerDropDownValueLabel.text(function () {
				return $(this).text().replace('(Not Rostered)', ''); 
			})
	}

	onSaveTask = () => {
		if (this.state.showUpdateReasonForm) {
			if ($('#update-reason-form').valid())
				this.saveTaskChanges();

			return;
		}

		const isTaskFormValid = $('#task-form').valid();
		const isRunSearchValid = !this.shouldRenderRunSearch() || $('#run-search-form').valid();
		const isRunFormValid = !this.isAddingRun() || $('#run-form').valid();

		if (!isTaskFormValid || !isRunFormValid || !isRunSearchValid || this.state.taskDetails.isStartTimeAdjustNeeded)
			return;

		if (this.props.taskId)
			return this.showUpdateReasonForm();

		this.saveNewTask();
	}

	handleClickAddNewRunCustomer = () => {
		const RunCustomers = this.state.taskDetails.RunCustomers || [];
		RunCustomers.push({ runNumber: '', runTypeId: undefined });
		this.changeForm({ RunCustomers });
	}

	handleClickRemoveRunCustomer = (runCustomerIndex: number) => {
		const RunCustomers = this.state.taskDetails.RunCustomers;
		if (!RunCustomers) return;

		RunCustomers.splice(runCustomerIndex, 1);
		this.changeForm({ RunCustomers });
	}

	handleChangeRunCustomer = (e: ChangeEvent<FormControl & HTMLInputElement>, runCustomerIndex: number) => {
		const RunCustomers = this.state.taskDetails.RunCustomers;
		if (!RunCustomers) return

		const { id, value } = e.target;
		RunCustomers[runCustomerIndex] = {
			...RunCustomers[runCustomerIndex],
			[id]: value
		};

		this.changeForm({ RunCustomers });
	}

	handleClickAddNewRunCustomerOrder = () => {
		const RunCustomerOrders = this.state.taskDetails.RunCustomerOrders || [];
		RunCustomerOrders.push(new RunCustomerOrderForm());
		this.changeForm({ RunCustomerOrders })
		.then(this.reorganizeDeliverySequence);
	}

	handleClickRemoveRunCustomerOrder = (runCustomerOrderIndex: number) => {
		const { RunCustomerOrders } = this.state.taskDetails;
		if (!RunCustomerOrders) return;

		RunCustomerOrders.splice(runCustomerOrderIndex, 1);
		this.changeForm({ RunCustomerOrders })
		.then(this.reorganizeDeliverySequence);
	}

	handleChangeRunCustomerOrder = (obj: IReactSelectReturn, runCustomerOrderIndex: number) => {
		const { RunCustomerOrders } = this.state.taskDetails;
		if (!RunCustomerOrders) return

		const { id, value } = obj;
		RunCustomerOrders[runCustomerOrderIndex] = {
			...RunCustomerOrders[runCustomerOrderIndex],
			[id]: value
		};

		this.changeForm({ RunCustomerOrders });
	}

	handleChangeSequenceRunCustomerOrder = (RunCustomerOrders: ItemInterface[]) => {
		this.changeForm({ RunCustomerOrders }, true)
			.then(this.reorganizeDeliverySequence)
	}

	handleChangeUpdateReason = (id: string, value: string, updateReasonIndex: number) => {
		const { updateReasonsFormData } = this.state;
		if (!updateReasonsFormData) return

		updateReasonsFormData[updateReasonIndex] = {
			...updateReasonsFormData[updateReasonIndex],
			[id]: value
		};

		this.setState({ updateReasonsFormData });
	}

	handleCloseModal = () => {
		if (this.state.anyChangeHasBeenMade)
			return confirm(
				"Are you sure you want to close? All unsaved data will be lost.", 
				this.props.onClose,
				() => {}
			)

		this.props.onClose();
	}

	renderTaskDetails = () => {
		const { taskDetails } = this.state;
		return (
			<TaskForm 
				taskDetails={taskDetails}
				formId="task-form"
				isChangePlaceEnabled
				isReadOnly={this.props.isReadOnly || taskDetails.isCancelled || taskDetails.actualFinishTimeLocal ? true : false}
				isEditMode={taskDetails.id && !taskDetails.isCancelled ? true : false}
				onChangeInput={this.handleChangeInput}
				onChangeSubContractor={this.handleChangeSubcontractor}
				onChangeWorker={this.handleChangeWorker}
				onChangeTaskType={this.handleChangeTaskType}
				onChangeStartTime={this.handleChangeStartTime}
				onChangeRun={this.handleChangeRun}
			/>
		)
	}

	renderRunSearch = () => {
		if (!this.shouldRenderRunSearch())
			return;
		
		const { runId, startingDepotId, date } = this.state.taskDetails;

		return (
			<Form id="run-search-form" validations={{ runId: 'required' }}>
				<FieldGroup label="This task type requires a Run to be executed" required>
					<RunsListSearch
						id="runId"
						startingDepotId={startingDepotId!}
						date={date!}
						value={runId}
						onChange={this.handleChangeRun}
					/>
				</FieldGroup>
			</Form>
		)
	}

	renderRunDetails = () => {
		const { isLoadingRun, errorLoadingRun, taskDetails, selectedRun } = this.state;

		if (errorLoadingRun)
			return <ErrorBox error={errorLoadingRun} retryFunc={this.fetchRunDetails} />;

		if (isLoadingRun)
			return <Loader text="Loading Run Details. Please, wait..." />;

		const isAddingRun = this.isAddingRun();
		if (!selectedRun && !isAddingRun)
			return null;

		const data = (isAddingRun ? taskDetails : selectedRun) as TaskFormClass;
		return (
			<RunForm
				{...data}
				formId="run-form"
				isReadOnly={this.props.isReadOnly || !isAddingRun || taskDetails.isCancelled || taskDetails.actualFinishTimeLocal ? true : false}
				isEditMode={taskDetails.id && !taskDetails.isCancelled? true : false}
				// hideRunCustomerFields={!isAddingRun}
				hideDate
				hideStartingDepot
				hideTimeFields
				showMainDriver={selectedRun && !isAddingRun ? true : false}
				onChangeInput={this.handleChangeInput}
				onChangeVehicle={this.handleChangeVehicle}
				onCheckMainVehicleNotTracked={this.handleCheckMainVehicleNotTracked}
				onCheckTrailer1NotTracked={this.handleCheckTrailer1NotTracked}
				onCheckTrailer2NotTracked={this.handleCheckTrailer2NotTracked}
				onChangeStartTime={this.handleChangeStartTime}
				onClickAddNewRunCustomer={this.handleClickAddNewRunCustomer}
				onClickRemoveRunCustomer={this.handleClickRemoveRunCustomer}
				onClickAddNewRunCustomerOrder={this.handleClickAddNewRunCustomerOrder}
				onClickRemoveRunCustomerOrder={this.handleClickRemoveRunCustomerOrder}
				onChangeRunCustomer={this.handleChangeRunCustomer}
				onChangeRunCustomerOrder={this.handleChangeRunCustomerOrder}
				onChangeSequenceRunCustomerOrder={this.handleChangeSequenceRunCustomerOrder}
			/>
		);
	}

	renderTaskStatus = () => {
		const { taskDetails } = this.state;
		if (!taskDetails || !taskDetails.id)
			return;

		const { text, className, colorClassName } = getTaskStatus(taskDetails);

		return (
			<Badge className={className}>
				<b className={colorClassName}>{ text }</b>
			</Badge>
		)
	}

	renderUpdateReasonForm = () => {
		const { taskDetails, anyChangeHasBeenMade, updateReasonsFormData } = this.state;

		return (
			<Fragment>
				{
					taskDetails.isCancelled && (
						<Callout title="Attention" icon="warning" color="warning">
							This task will be cancelled only for this date <b>({moment(taskDetails.date).format('DD/MM/YYYY')})</b> and it will still be created on the next week's roster despite this cancellation.
							If you wish for it to be deleted permanently, please delete it from the <b>TMS Run Master Template</b>
						</Callout>
					) 
				}
				{
					!anyChangeHasBeenMade ? (
						<Callout icon="warning" title="Attention" text="No changes have been made for this task" />
					) : (
						<Form id="update-reason-form">
							{
								updateReasonsFormData?.map((p, index) => (
									<div key={p.updateReasonCategoryId} className="update-reason-box">
										{ index !== 0 && <hr /> }
										<Well 
											bsSize="small"
											style={{ marginBottom: 0, fontWeight: 'bold' }}
										>
											{p.updateReasonLabel}
											{
												(p.oldValue || p.newValue) && (
													<Fragment>
														&nbsp;from <b className="text-danger"><u>{p.oldValue || 'None'}</u></b>
														&nbsp;to <b className="text-success"><u>{p.newValue || 'None'}</u></b>:
													</Fragment>
												)
											}
										</Well>
										<TaskUpdateReasonsList 
											id={`updateReasonId_${index}`}
											updateReasonCategoryId={p.updateReasonCategoryId}
											value={p.updateReasonId}
											className="required"
											onChange={(e: ChangeEvent<FormControl & HTMLInputElement>) => this.handleChangeUpdateReason('updateReasonId', e.target.value, index)}
										/>
										<FormControl 
											id={`updateDescription_${index}`}
											placeholder="Please explain why this change is being made..."
											componentClass="textarea"
											className="required"
											minLength={15}
											maxLength={255}
											rows={3}
											value={p.updateDescription}
											onChange={(e: ChangeEvent<FormControl & HTMLInputElement>) => this.handleChangeUpdateReason('updateDescription', e.target.value, index)}
										/>
									</div>
								))
							}
						</Form>
					)
				}
			</Fragment>
		)
	}

	renderTotals = () => {
		const { 
			totalPalletsAU_estimates, totalPalletsT3_estimates, totalCartons_estimates, runNumber,
			totalPalletsAU_actuals, totalPalletsT3_actuals
		} = this.state.taskDetails;

		if (!this.state.taskDetails.runId)
			return null;

		const totalPalletsActuals = (totalPalletsAU_actuals || 0) + (totalPalletsT3_actuals || 0);
		const totalPalletsEstimates = (totalPalletsAU_estimates || 0) + (totalPalletsT3_estimates || 0);

		return (
			<Well style={{ fontSize: '20px', textAlign: 'center' }}>
				<b>Pallets:</b> { totalPalletsAU_estimates === null ? 'No Data' : <span><b>{ totalPalletsActuals }</b> / {totalPalletsEstimates}</span> }
				&nbsp;|&nbsp;
				<b>Cartons:</b> { runNumber.includes('FS') === false ? 'N/A' : totalCartons_estimates === null ? 'No Data' : <b>{ totalCartons_estimates }</b> }
			</Well>
		)
	}

	renderBodyContent = () => {
		const { state } = this;

		if (state.errorLoadingTask)
			return <ErrorBox error={state.errorLoadingTask} retryFunc={this.fetchTaskDetails} />;

		if (state.isSaving || state.isLoadingTask)
			return <Loader isLoading={state.isLoadingTask} isSaving={state.isSaving} />;

		// return <RunOrdersTimeline
		// 	taskDetails={state.originalTaskDetails}
		// />

		return (
			<Fragment>
				{
					!state.showUpdateReasonForm && state.taskDetails.isCancelled && (
						<Callout 
							icon="times"
							title="Task Cancelled"
							text="This task was cancelled and can't be edited."
						/>
					)
				}
				{
					state.taskDetails.actualFinishTimeLocal && (
						<Callout 
							icon="info-circle"
							title="Completed Task"
							color="info"
							text="This task was completed and can't be edited. It can only be cancelled or deleted."
						/>
					)
				}
				{ state.errorSaving && <ErrorBox error={state.errorSaving} retryFunc={state.taskDetails.id ? this.saveTaskChanges : this.saveNewTask} /> }
				{
					state.showUpdateReasonForm ? (
						this.renderUpdateReasonForm()
					) : (
						this.showRunDetailsBlock() ? (
							<Row>
								<Col md={6}>
									<h3 className="title">Task Details</h3>
									{ this.renderTaskDetails() }
								</Col>
								<Col md={6}>
									<h3 className="title">Run Details</h3>
									{ this.renderRunSearch() }
									{ this.renderRunDetails() }
									{ this.renderTotals()}
								</Col>
							</Row>
						) : (
							this.renderTaskDetails()
						)
					)
				}
			</Fragment>
		)
	}

	render() {
		const { state, props } = this;

		return (
			<Modal
				id="task-modal"
				backdrop="static"
				bsSize={this.showRunDetailsBlock() ? 'large' : undefined}
				show={props.show}
				onHide={this.handleCloseModal}
				onEscapeKeyDown={this.handleCloseModal}
			>
				<Modal.Header closeButton>
					<Modal.Title>
						{ props.taskId ? `Task Details #${props.taskId}` : 'Add New Task' }
						&nbsp;{ this.renderTaskStatus() }
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{
						state.showAddStaffRosterModal && (
							<StaffRosterModal 
								show
								isWorkerReadOnly
								isDateReadOnly
								isStartingPlaceReadOnly
								isTaskTypeReadOnly
								dontAddTask
								initialDate={state.taskDetails.date}
								initialStartingDepotId={state.taskDetails.startingDepotId}
								initialStartingDepotName={state.taskDetails.startingDepotName}
								initialWorker={state.taskDetails.Worker}
								initialStartTime={state.taskDetails.startTime}
								initialMainTaskTypeId={state.taskDetails.taskTypeId}
								initialMainTaskTypeName={state.taskDetails.TaskType?.name}
								onClose={this.onCloseAddStaffRosterModal}
								onSaveComplete={this.onCompleteSaveStaffRoster}
							/>
						)
					}
					{ this.renderBodyContent() }
				</Modal.Body>
				{
					!state.isSaving && !state.isLoadingTask && !this.props.isReadOnly &&  (
						<Modal.Footer>
							{
								!state.errorLoadingTask && (
									<Fragment>
										{
											(
												this.basicDataEntered() 
												&& (!props.taskId || state.anyChangeHasBeenMade) 
												&& (!state.taskDetails.isCancelled || state.showUpdateReasonForm)
												&& (!props.taskId || !state.showUpdateReasonForm || (state.updateReasonsFormData && state.updateReasonsFormData.length > 0)) 
												// && !state.taskDetails.isStartTimeAdjustNeeded
												&& !state.taskDetails.actualFinishTimeLocal
											) && (
												<Button 
													bsStyle="success"
													className="pull-left"
													bsSize="lg"
													onClick={this.handleClickSaveTask}
												>
													<FontAwesome name="floppy-o" />&nbsp;&nbsp;
													{ props.taskId ? state.showUpdateReasonForm ? 'Submit Changes' : 'Save Changes' : 'Save Task' }
												</Button>
											)
										}
										{
											props.taskId && (
												!state.showUpdateReasonForm && !state.taskDetails.isCancelled && !state.taskDetails.actualFinishTimeLocal ? (
													<Button
														bsStyle="danger"
														onClick={this.handleClickCancelTask}
													>
														<FontAwesome name="times" /> Cancel Task
													</Button>
												) : state.showUpdateReasonForm && (
													<Button
														bsStyle="warning"
														bsSize="large"
														onClick={this.handleClickGoBack}
													>
														<Icon name="arrow-left"/> Go Back
													</Button>
												)
											)
										}
									</Fragment>
								)
							}
							<Button bsSize="lg" onClick={this.handleCloseModal}>
								<FontAwesome name="sign-out"/> Close Without Saving
							</Button>
						</Modal.Footer>
					)
				}
				{
					this.props.isReadOnly && (
						<Modal.Footer>
							<Button bsSize="lg" onClick={this.handleCloseModal}>
								<FontAwesome name="sign-out"/> Close
							</Button>
						</Modal.Footer>
					)
				}
			</Modal>
		)
	}
}

export default RosterTaskModal;