import moment from 'moment';
import React, { useState } from 'react';
import { Row } from 'react-bootstrap';
import { IFormField } from '../../../../../app/models/Application';
import { IPallet, IPalletStore, PalletDetailsForm as PalletDetailsFormClass } from '../../../../../app/models/Pallet';
import { FieldGroup, Form } from '../../../../common/uiElements';
import { PlacesListNew } from '../../../../common/lists';
import { DropDownList, RadioButtonList } from '../../../../common/inputs';
import { commodityList, palletLocationInTrailer, palletTypeList } from '../dataForLists';
import PalletsList from './PalletsList';
import PalletDetailsModal from './PalletDetailsModal';

interface IProps {
	pallet: PalletDetailsFormClass;
	isReadOnly?: boolean;
	isConsolidatedPallet?: boolean;
	onChangeInput: (id: string, value?: string) => void;
	onChangeStore: (store: IPalletStore) => void;
	onChangeConsolidationType: (wasPalletTimberKept: boolean) => void;
	onSaveConsolidatedPallet?: (consolidatedPallet: PalletDetailsFormClass) => void;
	onDeleteConsolidatedPallet?: (palletId: string) => void;
}

const PalletDetailsForm: React.FC<IProps> = props => {
	const { pallet } = props;

	const [selectedConsolidatedPallet, setSelectedConsolidatedPallet] = useState<IPallet | undefined>(undefined);

	const handleChangeConsolidationType = (e: React.ChangeEvent<HTMLInputElement>) => {
		props.onChangeConsolidationType(e.target.value === 'forkedUp');
	};
	
	const handleSaveConsolidatedPallet = (consolidatedPallet: IPallet) => {
		props.onSaveConsolidatedPallet && props.onSaveConsolidatedPallet(consolidatedPallet);
		setSelectedConsolidatedPallet(undefined);
	}

	const handleDeleteConsolidatedPallet = (consolidatedPallet: IPallet) => {
		// Toggle the pallet's deleted status as it can be undone
		consolidatedPallet.isDeleted = !consolidatedPallet.isDeleted;
		props.onSaveConsolidatedPallet && props.onSaveConsolidatedPallet(consolidatedPallet);
	}

	const detailFields: IFormField[] = [
		/** ID  */
		{ 
			id: 'id',
			label: 'ID', 
			sm: 4,
			readOnlyValue: pallet.palletId
		},

		/** Loaded By */
		{
			id: 'loadedBy',
			label: 'Loaded By',
			sm: 4,
			readOnlyValue: pallet.loadedBy?.name || '-'
		},

		/** Loaded At */
		{
			id: 'loadedOn',
			label: 'Loaded At',
			sm: 4,
			readOnlyValue: moment(pallet.loadedOn).format('HH:mm')
		},

		/** Store */
		{
			id: 'store',
			label: 'Store',
			required: true,
			sm: 12,
			readOnlyValue: pallet.store ? pallet.store.storeNumber + ' - ' + pallet.store.name : '-',
			isReadOnly: props.isReadOnly,
			inputComponent: (
				<PlacesListNew
					required
					id="store"
					type="deliveryStores"
					placeholder={pallet.store ? `${pallet.store.name} (${pallet.store.storeNumber})` : "--- STORE ---"}
					value={ pallet.store?.id && pallet.store?.id !== -1 ? pallet.store.id.toString() : '' }
					onChange={(place: any) => {
						props.onChangeStore && props.onChangeStore({
							id: place?.value ? parseInt(place.value) : -1,
							name: (place.fullObject as any)?.name || '',
							storeNumber: (place.fullObject as any)?.storeNumber || '',
						});
					}}
				/>
			)
		},

		/** Commodity */
		{
			id: 'commodity',
			label: 'Commodity',
			required: true,
			sm: 4,
			readOnlyValue: pallet.commodity,
			isReadOnly: props.isReadOnly,
			inputComponent: (
				<DropDownList
					required
					id={`commodity_` + pallet.palletId}
					placeholder="--- COMMODITY ---"
					value={ pallet.commodity }
					data={ commodityList }
					onChange={(commodity: string) => props.onChangeInput('commodity', commodity)}
				/>
			)
		},

		/** Type */
		{
			id: 'type',
			label: 'Type',
			required: true,
			sm: 4,
			readOnlyValue: pallet.palletType,
			isReadOnly: props.isReadOnly,
			inputComponent: (
				<DropDownList
					required
					id={`palletType_` + pallet.palletId}
					placeholder="--- PALLET TYPE ---"
					value={ pallet.palletType }
					data={ palletTypeList }
					onChange={(palletType: string) => props.onChangeInput('palletType', palletType)}
				/>
			)
		},

		/** Location */
		{
			id: 'location',
			label: 'Location',
			required: true,
			sm: 4,
			readOnlyValue: pallet.locationInTrailer,
			isReadOnly: props.isReadOnly,
			show: !props.isConsolidatedPallet,
			inputComponent: (
				<DropDownList
					required
					id={`locationInTrailer_` + pallet.palletId}
					placeholder="--- SIDE ---"
					value={ pallet.locationInTrailer || ""}
					data={ palletLocationInTrailer }
					onChange={(locationInTrailer?: string) => props.onChangeInput('locationInTrailer', locationInTrailer)}
				/>
			)
		},

		/** UPSTACKED OR FORKED UP (wasPalletTimberKept) */
		{
			id: 'wasPalletTimberKept',
			label: 'Consolidation Type',
			required: true,
			sm: 12,
			readOnlyValue: pallet.wasPalletTimberKept ? 'Forked Up' : 'Upstacked',
			isReadOnly: props.isReadOnly,
			show: !!props.isConsolidatedPallet,
			inputComponent: (
				<RadioButtonList 
					value={props.isConsolidatedPallet && pallet.wasPalletTimberKept === undefined ? '' : pallet.wasPalletTimberKept ? 'forkedUp' : 'upstacked'}
					items={[
						{ text: 'Upstacked', value: 'upstacked' },
						{ text: 'Forked up', value: 'forkedUp' }
					]}
					onChange={handleChangeConsolidationType}
				/>
			)
		}
	]

	const renderFields = (fields: IFormField[]) => (
		fields.map(field => {
			if (field.show === false)
				return null;

			return (
				<FieldGroup 
					key={field.label} 
					sm={field.sm} 
					label={field.label} 
					required={field.required} 
					optional={field.inputComponent && !props.isReadOnly && !field.required}
				>
					{
						(field.isReadOnly || !field.inputComponent) ? (
							<p>{ field.readOnlyValue || '-'}</p>
						) : (
							field.inputComponent
						)
					}
				</FieldGroup>
			)
		})
	)

	const validations = detailFields.filter(p => p.required).reduce((obj, item) => Object.assign(obj, { [item.id]: 'required' }), {});
	return (	
		<Form id="pallet-details-form" validations={validations}>
			<Row>
				{ renderFields(detailFields) }
			</Row>
			{/* <h3 className="title">Consolidated With</h3> */}
			{
				pallet.isUpstackedWith && (
					<>
						<h3 className="title">Consolidated With</h3>
						<PalletsList 
							hideStore
							locationInTrailer
							hideLoadedAt
							isConsolidatedPallet
							pallets={pallet.isUpstackedWith} 
							isReadOnly={props.isReadOnly}
							onSelectPallet={setSelectedConsolidatedPallet}
							onDeletePallet={handleDeleteConsolidatedPallet}
						/>
					</>
				)
			}
			{/* <br />
			<Button
				bsStyle="primary"
				onClick={handleAddPalletToConsolidate}
			>
				<FontAwesome name="plus" /> Add Pallet to consolidate
			</Button> */}

			{/* PALLET DETAILS MODAL  */}
			{
				selectedConsolidatedPallet && (
					<PalletDetailsModal 
						pallet={selectedConsolidatedPallet}
						isReadOnly={props.isReadOnly}
						onClose={() => setSelectedConsolidatedPallet(undefined)}
						onSave={handleSaveConsolidatedPallet}
					/>
				)
			}
		</Form>
	)
}

export default PalletDetailsForm;