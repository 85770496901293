import moment from 'moment';
import React, { ChangeEvent, Fragment, useState } from 'react';
import { Button, Col, FormControl, FormGroup, InputGroup, Row } from 'react-bootstrap';
import { IFormField, IReactSelectOption, IReactSelectReturn } from '../../../../../app/models/Application';
import { PalletUnloadDetailsForm as PalletUnloadDetailsFormClass, UnloadedByForm } from '../../../../../app/models/Pallet';
import { IVehicleBasicInfo, VehicleTypes } from '../../../../../app/models/Vehicle';
import { UserSearch, VehicleSelect } from '../../../../common/inputs';
import { PlacesListNew } from '../../../../common/lists';
import { FieldGroup, Form } from '../../../../common/uiElements';
import TimeField from 'react-simple-timefield';
import FontAwesome from 'react-fontawesome';

interface IProps extends PalletUnloadDetailsFormClass {
	isReadOnly?: boolean;
	onChange: (id: string, value?: string) => void;
	onChangeDepot: (depot?: IReactSelectReturn) => void;
	onChangeUnloadedBy: (index: number, unloadedBy: UnloadedByForm) => void;
	onChangeUnloadPercentage: (index: number, value: string) => void;
	onDeleteUnloadedBy: (index: number) => void;
	onAddLoader: () => void;
}

// regex to allow only numbers and max of 3 digits
const regexNumber = /^[0-9\b]{0,3}$/;

const PalletUnloadDetailsForm: React.FC<IProps> = props => {
	// Workaround to handle time field for finish time
	const [focusTimeFieldFinishTime, setFocusTimeFieldFinishTime] = useState(false);

	const handleChangeInput = (e: ChangeEvent<FormControl & HTMLInputElement>) => {
		const { id, value } = e.target;
		props.onChange(id, value);
	} 

	const handleChangeVehicle = (Vehicle?: IVehicleBasicInfo) => {
		props.onChange('fleetNumber', Vehicle?.fleetNumber || '');
	}

	const handleChangeDepot = (depot?: IReactSelectReturn) => {
		props.onChangeDepot(depot);
	}

	const handleChangeStartTime = (startTime: string) => {
		const startedAt = moment(props.startedAt).format(`YYYY-MM-DDT${startTime}`);
		props.onChange('startedAt', startedAt || '');
	}

	const handleChangeFinishTime = (finishTime: string) => {
		const $startedAt = moment(props.startedAt);
		const $finishedAt = moment(moment(props.startedAt).format(`YYYY-MM-DDT${finishTime}`));
		if ($finishedAt.isBefore($startedAt))
			$finishedAt.add(1, 'day');
		
		const finishedAt = $finishedAt.format(`YYYY-MM-DDTHH:mm`);
		props.onChange('finishedAt', finishedAt || '');
	}

	const handleChangeNumberInput = (e: ChangeEvent<FormControl & HTMLInputElement>) => {
		const { id, value } = e.target;
		
		// regex to allow only numbers and max of 3 digits
		if (value && !regexNumber.test(value))
			return;

		props.onChange(id, value);
	}

	const handleChangeLoader = (index: number, loader?: IReactSelectOption) => {
		const unloadedBy = props.unloadedBy[index];
		unloadedBy.id = loader?.value || '';
		unloadedBy.name = loader?.label || '';
		
		props.onChangeUnloadedBy(index, unloadedBy);
	}

	const handleChangeLoaderPercentage = (index: number, e: ChangeEvent<FormControl & HTMLInputElement>) => {
		const { value } = e.target;
		
		if (value && !regexNumber.test(value) && parseInt(value) > 100)
			return;

		const unloadedBy = props.unloadedBy[index];
		unloadedBy.unloadPercentage = value;
		props.onChangeUnloadedBy(index, unloadedBy);

		// Re-validate the form (otherwise the validation won't trigger for the other fields)
		(window as any).$('#pallet-unload-form').valid();
	}


	const detailFields: IFormField[] = [
		/** ID  */
		{ 
			id: 'id',
			label: 'ID', 
			readOnlyValue: props.id,
			sm: 3
		},

		// /** RUN NUMBER */
		// { 
		// 	id: 'run',
		// 	label: 'Run Number', 
		// 	readOnlyValue: props.Run.name,
		// 	sm: 3, 
		// 	required: true, 
		// 	// inputComponent: <UserSearch id="workerId" value={props.workerId} onChange={handleChangeUnloader} />
		// },

		/** DATE  */
		{ 
			id: 'date',
			label: 'Date', 
			readOnlyValue: props.startedAt ? moment(props.startedAt).format('DD/MM/YYYY') : '-', 
			sm: 3, 
			required: true,
		},

		/** DOW */
		{ 
			id: 'dow',
			label: 'Day Of Week', 
			readOnlyValue: props.startedAt ? moment(props.startedAt).format('dddd') : '-', 
			sm: 3
		},

		/** DEPOT */
		{ 
			id: 'depotId',
			label: 'Depot', 
			readOnlyValue: props.depot?.name,
			sm: 3, 
			required: true, 
			inputComponent: <PlacesListNew id="depotId" type="preStart" value={props.depot?.id?.toString() || ""} onChange={handleChangeDepot} />
		},

		/** DOCK NUMBER */
		{ 
			id: 'dockNumber',
			label: 'Dock Number', 
			readOnlyValue: props.dockNumber || '-',
			sm: 3, 
			required: true, 
			inputComponent: <FormControl id="dockNumber" value={props.dockNumber || ''} onChange={handleChangeInput} />
		},

		/** FLEET NUMBER */
		{ 
			id: 'fleetNumber',
			label: 'Fleet Number', 
			readOnlyValue: props.fleetNumber,
			sm: 3, 
			required: true, 
			inputComponent: <VehicleSelect id="fleetNumber" vehTypeFilter={[ VehicleTypes.RIGID, VehicleTypes.TRAILER, VehicleTypes.B_DOUBLE ]} value={props.fleetNumber} onChange={handleChangeVehicle} /> 
		},

		// /** LOADER THAT SUBMITTED THE LOAD */
		// { 
		// 	id: 'workerId',
		// 	label: 'Submitted By', 
		// 	readOnlyValue: props.UnloadedBy?.map(u => u.name).join(', ') || '-',
		// 	sm: 3, 
		// 	required: true, 
		// 	inputComponent: <UserSearch id="workerId" value={props.UnloadedBy[0]} onChange={props.onChangeUnloader} />
		// },

		/** START TIME */
		{ 
			id: 'startedAt',
			label: 'Start Time', 
			readOnlyValue: props.startedAt ? moment(props.startedAt).format('HH:mm') : '-', 
			sm: 3, 
			required: true,
			inputComponent: (
				<TimeField
					width={4}
					value={props.startedAt ? moment(props.startedAt).format('HH:mm') : ''} 
					input={<FormControl onFocus={(e: any) => e.target.select()} id="startedAt" />} 
					onChange={handleChangeStartTime}
				/>
			)
		},

		/** FINISH TIME */
		{ 
			id: 'finishedAt',
			label: 'Finish Time', 
			readOnlyValue: props.finishedAt ? moment(props.finishedAt).format('HH:mm') : '-', 
			sm: 3, 
			required: false, 
			inputComponent: (
				!props.finishedAt || !focusTimeFieldFinishTime ? (
					<FormControl 
						defaultValue={props.finishedAt ? moment(props.finishedAt).format('HH:mm') : ''} 
						onFocus={() => {
							setFocusTimeFieldFinishTime(true);
							if (!props.finishedAt)
								handleChangeFinishTime('00:00')
						}}
					/>
				) : (
					<TimeField
						width={4}
						value={props.finishedAt ? moment(props.finishedAt).format('HH:mm') : ''} 
						input={<FormControl autoFocus={focusTimeFieldFinishTime} id="finishedAt" />} 
						onChange={handleChangeFinishTime}
					/>
				)
			)
		},

		/** TOTAL EMPTY LOSCAM PALLETS */
		{ 
			id: 'totalEmptyPalletsLoscam',
			label: 'Total Empty LOSCAM', 
			readOnlyValue: props.totalEmptyPalletsLoscam || '0',
			sm: 3, 
			required: true, 
			inputComponent: <FormControl id="totalEmptyPalletsLoscam" value={props.totalEmptyPalletsLoscam || ''} onChange={handleChangeNumberInput} />
		},

		/** TOTAL EMPTY CHEP PALLETS */
		{ 
			id: 'totalEmptyPalletsChep',
			label: 'Total Empty CHEP', 
			readOnlyValue: props.totalEmptyPalletsChep || '0',
			sm: 3, 
			required: true, 
			inputComponent: <FormControl id="totalEmptyPalletsChep" value={props.totalEmptyPalletsChep || ''} onChange={handleChangeNumberInput} />
		},

		/** TOTAL EMPTY T3 PALLETS */
		{ 
			id: 'totalEmptyPalletsT3',
			label: 'Total Empty T3', 
			readOnlyValue: props.totalEmptyPalletsT3 || '0',
			sm: 3, 
			required: true, 
			inputComponent: <FormControl id="totalEmptyPalletsT3" value={props.totalEmptyPalletsT3 || ''} onChange={handleChangeNumberInput} />
		},

		/** TOTAL EMPTY PALLETS */
		{ 
			id: 'totalEmptyPallets',
			label: 'Total Empty Pallets', 
			readOnlyValue: parseInt(props.totalEmptyPalletsT3 || '0') + parseInt(props.totalEmptyPalletsLoscam || '0') + parseInt(props.totalEmptyPalletsChep || '0'),
			sm: 3, 
			required: true,
		},

		// /** TOTAL EMPTY CRATES */
		// { 
		// 	id: 'totalEmptyCrates',
		// 	label: 'Total Empty Crates', 
		// 	readOnlyValue: props.totalEmptyCrates || '-',
		// 	sm: 3, 
		// 	required: true, 
		// 	inputComponent: <FormControl id="totalEmptyCrates" value={props.totalEmptyCrates || ''} onChange={handleChangeNumberInput} />
		// },

		/** CANCEL DATE */
		{ 
			id: 'cancelledAt',
			label: 'Cancelled On', 
			readOnlyValue: props.cancelledAt ? moment(props.cancelledAt).format('DD/MM/YYYY HH:mm') : '-',
			sm: 3, 
			required: false, 
			show: props.cancelledAt ? true : false
		},

		/** CANCEL REASON */
		{ 
			id: 'cancelReason',
			label: 'Cancel Reason', 
			readOnlyValue: props.cancelReason,
			sm: 9, 
			required: false, 
			show: props.cancelReason ? true : false,
			inputComponent: <FormControl id="cancelReason" value={props.cancelReason || ''} onChange={handleChangeInput} />
		}
	]

	const renderFields = (fields: IFormField[]) => (
		fields.map(field => {
			if (field.show === false)
				return undefined;

			return (
				<FieldGroup 
					key={field.label} 
					sm={field.sm} 
					label={field.label} 
					required={field.required} 
					optional={field.inputComponent && !props.isReadOnly && !field.required}
				>
					{
						(field.isReadOnly || props.isReadOnly || !field.inputComponent) ? (
							<p>{ field.readOnlyValue || '-'}</p>
						) : (
							field.inputComponent
						)
					}
				</FieldGroup>
			)
		})
	)

	return (	
		<Form 
			id="pallet-unload-form" 
			validations={{
				...detailFields.filter(p => p.required).reduce((obj, item) => Object.assign(obj, { [item.id]: 'required' }), {}),
				...props.unloadedBy.reduce((obj, loader, i) => Object.assign(obj, { [`unloadPercentage_${i}`]: 'unloadedByTotal' }), {}),
			}}
		>
			<Row>
				{ renderFields(detailFields) }
			</Row>
			<Row>
				<FieldGroup
					label="Unloaded By"
					sm={6}
					required
				>
					{
						props.unloadedBy?.map((loader, i) => (
							<Fragment key={i}>
								{
									props.isReadOnly ? (
										<>
											{ i === 0 && <br /> }
											{ i > 0 && <br /> }
											<span>
												{loader.name} <b>({loader.unloadPercentage}%)</b>
											</span>
										</>
									) : (
										<FormGroup>
											<Row className="keep-margin">
												<Col xs={6} style={{ paddingLeft: 10 }}>
													<UserSearch 
														isRequired
														id={`workerId_${i}`}
														value={loader?.id || ""} 
														onChange={(loader: IReactSelectOption) => handleChangeLoader(i, loader)}
													/>
												</Col>
												<Col xs={4}>
													<InputGroup>
														<FormControl 
															id={`unloadPercentage_${i}`}
															value={loader.unloadPercentage} 
															className="unloadedBy-percentage required"
															onChange={(e: any) => handleChangeLoaderPercentage(i, e)}
														/>
														<InputGroup.Addon>%</InputGroup.Addon>
													</InputGroup>
												</Col>
												{
													i !== 0 && (
														<Col xs={2}>
															<Button
																bsStyle="danger"
																onClick={() => props.onDeleteUnloadedBy(i)}
															>
																<FontAwesome name="times" />
															</Button>
														</Col>
													)
												}
											</Row>
										</FormGroup>
									)
								}
							</Fragment>
						))
					}
					{
						!props.isReadOnly && (
							<Button
								bsStyle="primary"
								bsSize="sm"
								onClick={props.onAddLoader}
							>
								<FontAwesome name="plus" /> Add Loader
							</Button>
						)
					}
				</FieldGroup>
			</Row>
		</Form>
	)
}

export default PalletUnloadDetailsForm;