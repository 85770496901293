const root = '/Microservice/PalletLoader';
const loadsRoot = `${root}/Load`;
const unloadsRoot = `${root}/Unload`;

const endpoints = {
	loads: {
		root: loadsRoot,
		search: `${loadsRoot}/Search`,
		panel: `${loadsRoot}/Panel`,
		exportReport: `${root}/Report`,
		byId: (loadId: string) => `${loadsRoot}/${loadId}`,
		loadAuthorisation: (loadId: string) => `${loadsRoot}/${loadId}/LoadAuthorisation`,
		loadSummary: (loadId: string) => `${loadsRoot}/${loadId}/LoadSummary`
	},
	unloads: {
		root: unloadsRoot,
		search: `${unloadsRoot}/Search`,
		panel: `${unloadsRoot}/Panel`,
		byId: (loadId: string) => `${unloadsRoot}/${loadId}`,
	}
};

export default endpoints;