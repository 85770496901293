import { ReactComponentElement } from "react";
import { ReactSelectProps } from "react-select";
import { SortingRule } from "react-table";

/**
 * API
 */
export type RequestMethods = 'GET' | 'POST' | 'PUT' | 'DELETE';

export interface IRequestData {
	[key: string]: any;
	attachedFiles?: object[]
}

export class RequestObjectDebug {
	method: RequestMethods;
	request?: {
		endpoint: string
		data?: IRequestData,
	};
	response?: any;

	constructor(method: RequestMethods) {
		this.method = method;
	}
}

export interface IResponsePOST {
	dbKeyReference: string;
	extraData?: any;
}

export interface IResponsePUT {
	hasBeenUpdated: boolean;
}

export interface IResponseDELETE {
	hasBeenDeleted: boolean;
}

export class ListReturn<T> {
	totalPages: number = 0;
	totalRecords: number = 0;
	records: T[] = [];
}

export class ListRequestOptions {
	page?: number = 0;
	pageSize?: number = 10;
	sorted?: SortingRule[] = [];
	fields?: string[];
}

export interface IListComponentProps<T = any> extends ListRequestOptions {
	records?: T[];
	isLoading?: boolean;
	isReadOnly?: boolean;
	totalPages?: number;
	pageSizeOptions?: number[];
	onPageChange?: (page: number) => void;
	onPageSizeChange?: (pageSize: number) => void;
	onSortedChange?: (sorted: SortingRule[]) => void;
	onSelectRow?: (itemId: any, rowIndex?: number) => void;
}

export interface IListItem {
	id: number;
	name: string;
}

export interface IReactSelectProps extends Omit<ReactSelectProps, 'onChange' | 'value'> {
	value?: string;
	onChange?: (obj: IReactSelectReturn) => void;
	onLoadList?: (list: object[]) => void;
}

export interface IReactSelectOption {
	value: string;
	label: string;
	fullObject: object;
}

export interface IReactSelectReturn {
	id: string;
	value?: string;
	label?: string;
	fullObject?: object;
}


export interface IError {
	message: string;
	details?: string;
	errorsList?: {
		Property: string,
		Message: string	
	}[];
}

/**
 * OTHERS
 */
export type AustralianState = 'NSW' | 'QLD' | 'ACT' | 'NT' | 'VIC' | 'SA' | 'WA' | 'TAS';
export type DriversLicenceType = 'C' | 'LR' | 'MR' | 'HR' | 'HC' | 'MC' | undefined;
export type ColorsBootstrap = 'danger' | 'success' | 'warning' | 'primary' | 'info' | 'default';

export enum HoursRange {
  MORNING = '00:00-11:59',
  AFTERNOON = '12:00-17:59',
  EVENING = '18:00-23:59'
}

export interface IFormField {
	id: string;
	label: string;
	readOnlyValue?: any; 
	isReadOnly?: boolean;
	xs?: number; 
	sm?: number; 
	md?: number; 
	lg?: number; 
	required?: boolean;
	show?: boolean;
	inputComponent?: ReactComponentElement<any>;
}

export interface IYearWeekDropdownReturn { 
	week: number, 
	year: number, 
	startDate: string, 
	endDate: string 
}